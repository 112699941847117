
























































import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import KeybePhoneController from '../../controllers/KeybePhone.controller'
import KeybePhoneUseCases from '@/modules/DesignSystem/application/KeybePhoneUseCases'
import { dataToParsePhone, defaultCountryGetter } from '@/modules/DesignSystem/domain/controllers/KeybePhoneController'
import countries from '@/utils/countries'

export default {
  props: {
    value: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    label: {
      type: String
    },
    required: {
      type: Boolean
    }
  },
  computed: {
    selectedCountryClass () {
      return this.phone.code?.toLowerCase()
    }
  },
  mounted () {
    this.getCountries()
    this.getDefaultCountry()
    if (this.value) {
      this.setPhoneValue(this.value)
    }
  },
  data () {
    return {
      phone: {
        code: '',
        number: '',
        phone: '',
        countryName: '',
        valid: false
      },
      countrySearch: '',
      countries: [],
      keybePhoneController: new KeybePhoneController(),
      menu: false,
      loading: false,
      rules: {
        required: value => !!value || this.$t('requiredField')
      }
    }
  },
  methods: {
    checkPrevent (e: any): void {
      if (e?.key === '+') {
        e.preventDefault()
        e.stopPropagation()
      }

      if (e?.key === 'Enter') {
        e.preventDefault()
        e.stopPropagation()
        if (this.phone.phone) this.$emit('save', this.phone)
      }
    },
    async getDefaultCountry () {
      this.loading = true
      const response: defaultCountryGetter = await KeybePhoneUseCases.getDefaultCountry(this.keybePhoneController)
      this.phone.code = response.code
      this.phone.number = response.number
      this.phone.countryName = response.country
      this.loading = false
    },
    async getCountries () {
      this.countries = countries
      if (this.countrySearch) {
        this.countries = this.countries.filter((country) => {
          return country.country.toLowerCase().includes(this.countrySearch.toLowerCase())
        })
      }
    },
    selectCountry (country): void {
      const actualCountry = country
      this.phone.code = actualCountry[0].code.toUpperCase()
      this.phone.number = actualCountry[0].number
      this.phone.countryName = actualCountry[0].country
      this.menu = false
      this.countrySearch = ''
    },
    countryClass (country): string {
      return country?.code?.toLowerCase()
    },
    async setPhoneValue (e: string) {
      this.phone = await KeybePhoneUseCases.setPhoneFromString(this.keybePhoneController, e)
    },
    async setPhoneNumber (e: any): Promise<void> {
      if (!this.loading) {
        const inputValue = e
        const data: dataToParsePhone = {
          phone: inputValue,
          currentPhone: this.phone
        }
        this.phone = await KeybePhoneUseCases.parsePhone(this.keybePhoneController, data)
      }
      this.$emit('input', this.phone)
    },
    async setPhonePaste (e: any) {
      if (!this.loading) {
        this.loading = true
        const phoneS: string = (e.clipboardData).getData(CDPFieldsEnums.TEXT).replaceAll(' ', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '')
        const data: dataToParsePhone = {
          phone: phoneS,
          currentPhone: this.phone
        }
        this.phone = await KeybePhoneUseCases.parsePhone(this.keybePhoneController, data)
        this.loading = false
      }
      if (this.phone.phone) this.$emit('input', this.phone)
    }
  },
  watch: {
    value (val: string) {
      console.log(this.value)
    }
  }
}
