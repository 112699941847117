import VueRouter from 'vue-router'
import link from '@/utils/links'
import store from '@/store'
import V6RedirectionController from '@/controllers/V6RedirectionController'
import { UserTypes } from '@/modules/Permissions/domain/UserTypes'
import UserSectionController from '@/modules/Permissions/infrastructure/UserSection.controller'
import UserSectionUseCases from '@/modules/Permissions/application/UserSectionUseCases'
import AppStoreController from '@/shared/infrastructure/storeControllers/AppStore.controller'
import PlanUseCases from '@/modules/consumptions/application/PlanUseCases'
import AppConfigController from '@/modules/consumptions/infrastructure/controllers/AppConfig.controller'
import { dataToGetAppConfig } from '@/modules/consumptions/domain/PlanController'
import LogInController from '@/modules/auth/infrastructure/controllers/Login.controller'
import LogInUseCases from '@/modules/auth/application/LoginUseCases'
import { dataToLogInByToken } from '@/modules/auth/domain/LoginController'
// import DeviceInfoController from '@/modules/Config/GeneralUserConfiguration/infrastructure/controller/DeviceInfo.controller'

// Lazy loading all the components
const ResetPasswordScreen = () => ({
  component: new Promise((resolve) => {
    try {
      const component = import(
        /* webpackChunkName: "auth" */ '@/modules/auth/infrastructure/ui/screens/ResetPasswordScreen/ResetPasswordScreen.vue'
      )
      resolve(component)
    } catch (error) {
      throw new Error('ResetPasswordScreen failed', error)
    }
  }).catch((error) => {
    AppStoreController.setRedirectRoute(link.resetPassword)
    window.location.reload()
    console.log('ResetPasswordScreen failed', error)
  })
})

// import as a promise to avoid circular dependency

const PaymentScreen = () => ({
  component: new Promise((resolve) => {
    try {
      const component = import(
        /* webpackChunkName: "payment" */ '@/modules/payment/infrastructure/ui/screens/PaymentScreen/PaymentScreen.vue'
      )
      resolve(component)
    } catch (error) {
      throw new Error('PaymentScreen failed', error)
    }
  }).catch((error) => {
    AppStoreController.setRedirectRoute(link.payment)
    window.location.reload()
    console.log('PaymentScreen failed', error)
  })
})

const ConversationsScreen = () => ({
  component: new Promise((resolve) => {
    try {
      const component = import(
        /* webpackChunkName: "conversations" */ '@/modules/conversations/infrastructure/ui/Screens/ConversationsScreen/ConversationsScreen.vue'
      )
      resolve(component)
    } catch (error) {
      throw new Error('ConversationsScreen failed', error)
    }
  }).catch((error) => {
    AppStoreController.setRedirectRoute(link.conversations)
    window.location.reload()
    console.log('ConversationsScreen failed', error)
  })
})

const CDPScreen = () => ({
  component: new Promise((resolve) => {
    try {
      const component = import(
        /* webpackChunkName: "people" */ '@/modules/CDP/shared/infrastructure/Screens/CDPScreen/CDPScreen.vue'
      )
      resolve(component)
    } catch (error) {
      throw new Error('PeopleScreen failed', error)
    }
  }).catch((error) => {
    AppStoreController.setRedirectRoute(link.people)
    window.location.reload()
    console.log('PeopleScreen', error)
  })
})

const SalesScreen = () => ({
  component: new Promise((resolve) => {
    try {
      const component = import(
        /* webpackChunkName: "sales" */ '@/modules/Sales/shared/infrastructure/Screens/SalesScreen/SalesScreen.vue'
      )
      resolve(component)
    } catch (error) {
      throw new Error('OpportunityScreen failed', error)
    }
  }).catch((error) => {
    AppStoreController.setRedirectRoute(link.sales)
    window.location.reload()
    console.log('OpportunityScreen failed', error)
  })
})

const LoginScreen = () => ({
  component: new Promise((resolve) => {
    try {
      const component = import(
        /* webpackChunkName: "auth" */ '@/modules/auth/infrastructure/ui/screens/LoginScreen/LoginScreen.vue'
      )
      resolve(component)
    } catch (error) {
      throw new Error('LoginScreen failed', error)
    }
  }).catch((error) => {
    AppStoreController.setRedirectRoute(link.signin)
    window.location.reload()
    console.log('LoginScreen failed', error)
  })
})

const AssistedSignupScreen = () => ({
  component: new Promise((resolve) => {
    try {
      const component = import(
        /* webpackChunkName: "auth" */ '@/modules/auth/infrastructure/ui/screens/AssistedSignup/AssistedSignupScreen.vue'
      )
      resolve(component)
    } catch (error) {
      throw new Error('AssistedSignupScreen failed', error)
    }
  }).catch((error) => {
    AppStoreController.setRedirectRoute(link.assistedSignup)
    window.location.reload()
    console.log('AssistedSignupScreen failed', error)
  })
})

// const SignupScreen = () => ({
//   component: new Promise((resolve) => {
//     try {
//       const component = import(
//         /* webpackChunkName: "auth" */ '@/modules/auth/infrastructure/ui/screens/SignupScreen/SignupScreen.vue'
//       )
//       resolve(component)
//     } catch (error) {
//       throw new Error('SignupScreen failed', error)
//     }
//   }).catch((error) => {
//     AppStoreController.setRedirectRoute(link.signup)
//     window.location.reload()
//     console.log('SignupScreen failed', error)
//   })
// })
const GuestLoginScreen = () => ({
  component: new Promise((resolve) => {
    try {
      const component = import(
        /* webpackChunkName: "auth" */ '@/modules/auth/infrastructure/ui/screens/GuestLoginScreen/GuestLoginScreen.vue'
      )
      resolve(component)
    } catch (error) {
      throw new Error('GuestLoginScreen failed', error)
    }
  }).catch((error) => {
    AppStoreController.setRedirectRoute(link.signin)
    window.location.reload()
    console.log('GuestLogin failed', error)
  })
})

//  mobile connections
const FacebookMobileConfig = () =>
  import(
    /* webpackChunkName: "mobile" */ '@/components/configuration/channels/mobile/FacebookMobileConfig.vue'
  )
const InstagramMobileConfig = () =>
  import(
    /* webpackChunkName: "mobile" */ '@/components/configuration/channels/mobile/InstagramMobileConfig.vue'
  )
const FacebookRefreshConfig = () =>
  import(
    /* webpackChunkName: "mobile" */ '@/components/configuration/channels/mobile/FacebookRefreshConfig.vue'
  )
const InstagramRefreshConfig = () =>
  import(
    /* webpackChunkName: "mobile" */ '@/components/configuration/channels/mobile/InstagramRefreshConfig.vue'
  )

const validateInactive = async (
  to: { [key: string]: any },
  _: { [key: string]: any },
  next: Function
) => {
  const state =
    JSON.parse(localStorage.getItem(process.env.VUE_APP_STORE_NAME)) || {}
  const status = state.AppStore?.selectedApp?.status
  const statusApp = store.getters['AppStore/getSelectedApp']?.status || ''
  let inactiveApp = false

  inactiveApp = (status !== 'active')
  if (statusApp) {
    inactiveApp = (statusApp !== 'active')
  }

  if (to.name === 'payment' && !inactiveApp && to.path !== link.conversations) {
    return next({ path: link.conversations })
  } else {
    return next()
  }
}

const authGuard = async (
  to: { [key: string]: any },
  _: { [key: string]: any },
  next: Function
) => {
  const unProtectedRoutes = [
    link.signin,
    link.signup,
    link.gql,
    link.invitedWizard,
    link.assistedSignup,
    link.firstLogin
  ]
  const state =
    JSON.parse(localStorage.getItem(process.env.VUE_APP_STORE_NAME)) || {}
  const status = state.AppStore?.selectedApp?.status
  const inactiveApp = !(status === 'active' || status == null || status === true || status === false || status === 200)

  const isLoggedIn = state.UserStore?.isLoggedIn || false
  const logout = to.query?.logout
  const register = to.query?.register

  const redirectRoute = state.AppStore?.redirectRoute
  const tokenV6: string = await to.query?.tokenV6
  const appId: string = await to.query?.appId

  console.log('tokenV6', tokenV6, 'appId', appId, 'redirectRoute', redirectRoute)

  if (redirectRoute && redirectRoute !== to.path && !tokenV6 && !appId) {
    return next({ path: redirectRoute })
  }

  await updateBillingInfo()

  if (logout) {
    console.log('logout')
    await store.dispatch('UserStore/logout')
    return next({ path: link.signin })
  }

  if (register) {
    await store.dispatch('UserStore/logout')
    // remove register query param
    delete to.query.register
    return next({ path: link.signup, query: to.query })
  }

  if (tokenV6) {
    await updateBillingInfo()
    router.app.$store.commit('UserStore/SET_LOADING', true, { root: true })
    let redirect = false
    if (appId) {
      const controller = new LogInController()
      const data: dataToLogInByToken = {
        token: tokenV6,
        app: appId
      }
      redirect = await LogInUseCases.loginByToken(controller, data)
    } else redirect = await V6RedirectionController.handleRedirection(tokenV6)
    if (!redirect) {
      router.app.$store.commit('UserStore/SET_LOADING', false, { root: true })
      router.app.$store.commit('UserStore/CHANGE_KEYBE_AUTH', false, {
        root: true
      })
      return next({ path: link.signin })
    } else {
      router.app.$store.commit('UserStore/SET_LOADING', false, { root: true })
      if (to.path !== link.conversations && to.path !== link.home) {
        return next({ path: to.path })
      }
      return next({ path: link.conversations })
    }
  } else {
    // Send users to conversations instead of super admin
    if (to.path === link.home) {
      console.log('home')
      return next({ path: link.conversations })
    }
    // Validate if is Rigo to see Rigo Dashboard
    if (
      to.path === link.panel &&
      state.AppStore?.selectedApp.uuid !== '171xeml22l0h7ork9'
    ) {
      console.log('panel')
      return next({ path: link.conversations })
    }
    if (!unProtectedRoutes.includes(to.path) && !isLoggedIn) {
      if (localStorage.getItem(process.env.VUE_APP_STORE_NAME)) {
        console.log('store')
        return next({ path: link.signin })
      } else {
        return next({ path: link.signin })
      }
    } else if (unProtectedRoutes.includes(to.path) && isLoggedIn) {
      return next(link.conversations)
    }

    const user = state?.UserStore?.user
    const userApp = state?.UserStore?.selectedUserApp
    const userType = user?.isSuperAdmin
      ? UserTypes.SUPER_ADMIN
      : userApp?.role || UserTypes.AGENT
    const controller = new UserSectionController()
    const canEnter = UserSectionUseCases.canEnterToSection(
      controller,
      userType,
      to.path
    )
    // const userLoggedIn = state.UserStore?.isLoggedIn

    if (
      inactiveApp &&
      to.fullPath !== link.signin &&
      to.fullPath !== link.signup &&
      to.fullPath !== link.firstLogin
    ) {
      console.log('inactiveApp')
      return next({ path: link.payment })
    }

    if (!canEnter) {
      console.log('canEnter')
      return next({ path: link.conversations })
    }

    return next()
  }
}

const updateBillingInfo = async () => {
  const controller = new AppConfigController()

  const token: string = store.getters['UserStore/getToken'] || ''
  const appId: string = store.getters['AppStore/getSelectedApp']?.uuid || ''

  const data: dataToGetAppConfig = {
    token: token,
    appId: appId
  }
  const appConfig = await PlanUseCases.getAppConfig(controller, data)
  if (!appConfig) return
  router.app.$store.commit('BillingStore/SET_APP_CONFIG', appConfig?.data, {
    root: true
  })
}

const routes = [
  {
    path: link.home,
    component: ConversationsScreen,
    beforeEnter: authGuard
  },
  {
    path: link.signin,
    component: LoginScreen,
    beforeEnter: authGuard
  },
  {
    path: link.firstLogin,
    component: GuestLoginScreen,
    beforeEnter: authGuard
  },
  {
    name: 'ResetPassword',
    path: link.resetPassword,
    component: ResetPasswordScreen
  },
  {
    path: link.payment,
    component: PaymentScreen,
    name: 'payment',
    beforeEnter: validateInactive
  },
  {
    path: link.conversations,
    component: ConversationsScreen,
    name: 'Conversations',
    beforeEnter: authGuard
  },
  {
    path: link.signup,
    component: AssistedSignupScreen,
    beforeEnter: authGuard
  },
  // {
  //   path: link.signup,
  //   component: SignupScreen,
  //   beforeEnter: authGuard
  // },
  {
    path: link.people,
    component: CDPScreen,
    beforeEnter: authGuard,
    alias: [link.businesses]
  },
  {
    path: link.segment,
    name: 'Segment',
    component: CDPScreen,
    beforeEnter: authGuard
  },
  {
    path: link.business,
    name: 'Business',
    component: CDPScreen,
    beforeEnter: authGuard
  },
  {
    path: link.person,
    name: 'Person',
    component: CDPScreen,
    beforeEnter: authGuard
  },
  { path: '/sales', component: SalesScreen, beforeEnter: authGuard },
  {
    path: link.sales,
    name: 'Pipeline',
    component: SalesScreen,
    beforeEnter: authGuard
  },
  {
    path: `${link.instagramMobileConfig}${link.extensionPath}`,
    component: InstagramMobileConfig
  },
  {
    path: `${link.facebookMobileConfig}${link.extensionPath}`,
    component: FacebookMobileConfig
  },
  {
    path: `${link.instagramMobileConfig}${link.extensionPath}${link.extensionInstagram}`,
    component: InstagramRefreshConfig
  },
  {
    path: `${link.facebookMobileConfig}${link.extensionPath}${link.extensionPage}`,
    component: FacebookRefreshConfig
  }
]

const router = new VueRouter({
  base: process.env.VUE_APP_BASE_ROUTE,
  mode: 'history',
  routes
})

export default router
