




















































































































































































































import GeneralUserConfigurationContainer from './GeneralUserConfigurationContainer'
export default GeneralUserConfigurationContainer
