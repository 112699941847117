


























import DeleteSpaceWarning from './DeleteSpaceWarning'
export default DeleteSpaceWarning
