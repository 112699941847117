
































































































































































































































































































import { KeybeTextTypeEnum } from '@/modules/DesignSystem/domain/models/KeybeTextTypeEnum'
import { citiesGetter, countriesGetter, dataToGetCities, dataToGetCountries, dataToGetStates, statesGetter, timezonesGetter } from '../ui/details/sections/Location/domain/LocationController'
import { mapState, mapActions, mapGetters } from 'vuex'
import LocationUseCases from '../ui/details/sections/Location/application/LocationUseCases'
import LocationController from '../ui/details/sections/Location/infrastructure/controllers/Location.controller'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import { formattedPhone } from '@/modules/DesignSystem/domain/controllers/KeybePhoneController'
import { Languages } from '@/components/shared/enums/Languages'
import UserUseCases from '@/modules/Config/GeneralUserConfiguration/application/UserUseCases'
import { dataToUpdateUser, dataToUploadImage, userGetterError, userImageUpdater } from '@/modules/Config/GeneralUserConfiguration/domain/UserController'
import UserController from '@/modules/Config/GeneralUserConfiguration/infrastructure/controller/User.controller'
import { debounce } from 'lodash'
import { LoggedUser } from '@/modules/Config/GeneralUserConfiguration/domain/LoggedUser'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '../enums/icons/KeybeIconType'
import KeybeLoader from '@/shared/infrastructure/ui/Loader.vue'
import TeamUseCases from '@/modules/Config/TeamConfiguration/application/TeamUseCases'
import { dataToGetTermsByTeamMember } from '@/modules/Config/TeamConfiguration/domain/TeamController'
import TeamController from '@/modules/Config/TeamConfiguration/infrastructure/controllers/Team.controller'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import { Country } from '../ui/details/sections/Location/domain/Location'
import { appUpdater, dataToUpdateApp } from '@/modules/Apps/domain/AppsController'
import EmitToast from '@/utils/EmitToast'
import KeybePhoneV from '@/modules/DesignSystem/infrastructure/components/KeybePhoneV/KeybePhoneV.vue'
export default {
  components: {
    KeybeAvatar,
    KeybePhoneV,
    KeybeIcon,
    KeybeLoader
  },
  computed: {
    ...mapState('AppStore', ['selectedApp', 'language']),
    ...mapState('UserStore', ['token', 'user']),
    ...mapGetters('UserStore', ['isAdminOrAbove']),
    agreementLink (): string {
      const labelMap: {[key: string]: string} = {
        [Languages.ES]: `${process.env.VUE_APP_KEYBE_CO_URL}legales/#acuerdo-de-servicio`,
        [Languages.EN]: `${process.env.VUE_APP_KEYBE_US_URL}legal/#service-agreement`
      }
      return labelMap[this.language] || 'https://keybe.ai/legales/#acuerdo-de-servicio'
    },
    languages (): { name: string, value: string }[] {
      return [
        { name: this.$t(Languages.EN), value: Languages.EN },
        { name: this.$t(Languages.ES), value: Languages.ES },
        { name: this.$t(Languages.PT), value: Languages.PT }
      ]
    },
    formattedPhone () {
      return this.user?.countryCode && this.user?.phone ? `+${+this.user?.countryCode}${this.user?.phone}` : ''
    },
    formattedPhoneBrand () {
      return this.selectedApp?.phone ? `+${this.selectedApp?.phone}` : ''
    },
    photoFile () {
      const photo = this.userUpdate.photo
      return photo ? new File([], photo) : null
    },
    brandPhotoFile () {
      const photo = this.brandUpdate.photo
      return photo ? new File([], photo) : null
    }
  },
  async mounted () {
    if (!this.user?.uuid) {
      this.$emit('cancel')
      return
    }
    this.loading = true
    this.isOwner = await AppsUseCases.isUserOwner(this.appsController, { appUUID: this.selectedApp?.uuid, user: this.user })
    await this.setInitial()
    await this.getCountries()
    await this.getCountriesBrand()
    await this.getTimezones()
    await this.getCountryCodes()
    this.loading = false
  },
  data () {
    return {
      KeybeTextTypeEnum,
      CDPFieldsEnums,
      KeybeIconType,
      countries: [],
      states: [],
      cities: [],
      loadingCountriesBrand: false,
      loadingCountries: false,
      loadingStates: false,
      loadingCities: false,
      countrySearch: '',
      stateSearch: '',
      citySearch: '',
      countrySearchBrand: '',
      countriesBrand: [],
      timezones: [],
      loading: false,
      loadingSave: false,
      loadingTimezones: false,
      isOwner: false,
      countryCodes: [],
      loadingCountryCodes: false,
      countryCodePage: 1,
      countryCodeSearch: '',
      locationController: new LocationController(),
      userController: new UserController(),
      teamController: new TeamController(),
      appsController: new AppsController(),
      brandUpdate: {
        name: '',
        email: '',
        phone: '',
        defaultCountryCode: '',
        country: '',
        timeZone: '',
        lang: '',
        photo: '',
        uuid: ''
      },
      userUpdate: {
        appUUID: '',
        name: '',
        lastName: '',
        email: '',
        phone: '',
        countryCode: null,
        country: '',
        address_country: '',
        address_province: '',
        address_city: '',
        address_zone: '',
        birthday: '',
        photo: '',
        accept_terms: false,
        terms: 0
      },
      birthdateMenu: false,
      formValid: false,
      rules: {
        required: value => !!value || this.$t('requiredField')
      }
    }
  },
  methods: {
    ...mapActions('UserStore', ['setUserCompletionClose']),
    setInitial (): void {
      const data: dataToGetTermsByTeamMember = {
        appUUID: this.selectedApp?.uuid || '',
        user: this.user
      }
      this.brandUpdate = this.selectedApp || {}
      this.userUpdate = this.user || {}
      this.userUpdate.terms = TeamUseCases.areTermsAcceptedByApp(this.teamController, data)
      if (this.userUpdate.birthdate) {
        const valueTime = this.userUpdate.birthday?.toString()?.substring(0, 10) + 'T12:00:00.000Z'
        const date = new Date(valueTime)
        this.userUpdate.birthday = date.toISOString().substring(0, 10)
      }
      this.countrySearch = this.userUpdate.address_country || ''
      this.stateSearch = this.userUpdate.address_province || ''
      this.citySearch = this.userUpdate.address_city || ''
      this.countrySearchBrand = this.brandUpdate.country || ''
    },
    setCountryBrandSearch: debounce(function (e: string): void {
      if (e === this.countrySearchBrand) return
      this.countrySearchBrand = e
      this.getCountriesBrand()
    }, 600),
    setCountrySearch: debounce(function (e: string): void {
      if (e === this.countrySearch) return
      this.countrySearch = e
      this.getCountries()
    }, 600),
    setStateSearch: debounce(function (e: string): void {
      if (e === this.stateSearch) return
      this.stateSearch = e
      this.getStates()
    }, 600),
    setCitySearch: debounce(function (e: string): void {
      if (e === this.citySearch) return
      this.citySearch = e
      this.getCities()
    }, 600),
    setCountrySelectBrand (e: string): void {
      if (e) {
        this.selectedCountryBrand = e
        this.brandCountry = e
      }
    },
    setCountrySelect (e: string): void {
      if (e) this.user.address_country = e
      this.stateSearch = ''
      this.citySearch = ''
      this.user.address_province = ''
      this.user.address_city = ''
      this.getStates()
    },
    setStateSelect (e: string): void {
      if (e) this.user.address_province = e
      this.citySearch = ''
      this.selectedCity = ''
      this.getCities()
    },
    setCountryCodeSearch (e: string): void {
      if (!e) this.brandDefaultCountryCode = ''
      this.countryCodePage = 1
      this.countryCodeSearch = e
      this.getCountryCodes()
    },
    getNext (field: string): void {
      if (this.loadingCountryCodes) return
      this.countryCodePage++
      this.getCountryCodes()
    },
    async getCountryCodes (): Promise<void> {
      this.loadingCountryCodes = true
      const data: dataToGetCountries = {
        token: this.token,
        app: this.selectedApp?.uuid,
        query: {
          offset: this.countryCodePage,
          limit: 20,
          search: this.countryCodeSearch || ''
        }
      }
      const response: countriesGetter = await LocationUseCases.getCountries(this.locationController, data)
      if (response?.status) {
        const countries: Country[] = response?.countries
        countries.forEach((country: Country) => {
          this.countryCodes.push({
            name: country.name + `${country.phone_code.includes('+') ? ' ' : ' +'}` + country.phone_code,
            code: country.phone_code
          })
        })
      }
      this.loadingCountryCodes = false
    },
    async getTimezones (): Promise<void> {
      this.loadingTimezones = true
      const response: timezonesGetter = await LocationUseCases.getTimezones(this.locationController)
      if (response?.status) {
        const timezones: string[] = response?.timezones
        this.timezones = timezones
      }
      this.loadingTimezones = false
    },
    async getCountriesBrand (): Promise<void> {
      this.loadingCountriesBrand = true
      const data: dataToGetCountries = {
        token: this.token,
        app: this.selectedApp?.uuid || '',
        query: {
          limit: 20,
          offset: 0,
          search: this.countrySearchBrand || ''
        }
      }
      const response: countriesGetter = await LocationUseCases.getCountries(
        this.locationController,
        data
      )
      if (response?.status) {
        this.countriesBrand = response?.countries
      }
      this.loadingCountriesBrand = false
    },
    async getCountries (): Promise<void> {
      this.loadingCountries = true
      const data: dataToGetCountries = {
        token: this.token,
        app: this.selectedApp?.uuid || '',
        query: {
          limit: 20,
          offset: 0,
          search: this.countrySearch || ''
        }
      }
      const response: countriesGetter = await LocationUseCases.getCountries(
        this.locationController,
        data
      )
      if (response?.status) {
        this.countries = response?.countries
        if (this.user.address_country) this.getStates()
      }
      this.loadingCountries = false
    },
    async getStates (): Promise<void> {
      this.loadingStates = true
      this.states = []
      const variables: dataToGetStates = {
        app: this.selectedApp?.uuid,
        token: this.token,
        countryCode: this.user.address_country,
        query: {
          limit: 20,
          offset: 0,
          search: this.stateSearch || ''
        }
      }
      const response: statesGetter = await LocationUseCases.getStates(
        this.locationController,
        variables
      )
      if (response?.status) {
        this.states = response?.states
        if (this.user.address_province) this.getCities()
      }
      this.loadingStates = false
    },
    async getCities (): Promise<void> {
      this.loadingCities = true
      this.cities = []

      const variables: dataToGetCities = {
        app: this.selectedApp?.uuid,
        token: this.token,
        countryCode: this.user.address_country,
        provinceCode: this.user.address_province,
        query: {
          limit: 20,
          offset: 0,
          search: this.citySearch || ''
        }
      }
      const response: citiesGetter = await LocationUseCases.getCities(
        this.locationController,
        variables
      )
      if (response?.status) {
        this.cities = response?.cities
      }
      this.loadingCities = false
    },
    setTerms (e: boolean): void {
      this.terms = e ? 1 : 0
    },
    setPhone (e: formattedPhone): void {
      this.userUpdate.country = e.code
      this.userUpdate.countryCode = +e.number
      this.userUpdate.phone = e.phone
      this.userUpdate.valid = e.valid
    },
    setPhoneBrand (e: formattedPhone): void {
      this.brandUpdate.phone = `${e.number}${e.phone}`
    },
    openFileInput (): void {
      this.$refs.imageInput.click()
    },
    openFileInputBrand (): void {
      this.$refs.imageInputBrand.click()
    },
    async onFilePicked (img: File): Promise<void> {
      URL.createObjectURL(img)
      const data: dataToUploadImage = {
        img,
        uuid: this.user?.uuid,
        appId: this.selectedApp?.uuid,
        token: this.token,
        preventUpdate: true
      }
      const response: userImageUpdater = await UserUseCases.uploadUserImage(this.userController, data)
      if (response.status) {
        this.userUpdate.photo = response?.data?.info?.location
      }
    },
    async onFilePickedBrand (img: File): Promise<void> {
      URL.createObjectURL(img)
      const data: dataToUploadImage = {
        img,
        uuid: this.user?.uuid,
        appId: this.selectedApp?.uuid,
        token: this.token,
        preventUpdate: true
      }
      const response: userImageUpdater = await UserUseCases.uploadUserImage(this.userController, data)
      if (response.status) {
        this.brandUpdate.photo = response?.data?.info?.location
      }
    },
    async submit (): Promise<void> {
      this.$refs.formRef.validate()
      if (!this.formValid) {
        EmitToast.emitErrors(this.$t('formInvalid'))
        return
      }
      this.loadingSave = true
      if (this.isOwner) await this.saveBrandInfo()
      await this.saveUserInfo()
      this.loadingSave = false
    },
    async saveBrandInfo (): Promise<void> {
      try {
        const data: dataToUpdateApp = {
          app: this.brandUpdate,
          token: this.token
        }
        const response: appUpdater = await AppsUseCases.updateAppInfo(this.appsController, data)
        if (response?.message) {
          EmitToast.emitErrors(response)
          this.updatedValue = {}
        }
      } catch (e) {
        console.error(e)
      }
    },
    async saveUserInfo (): Promise<void> {
      const data: dataToUpdateUser = {
        uuid: this.user?.uuid,
        token: this.token,
        updateInput: this.userUpdate
      }

      data.updateInput.appUUID = this.selectedApp?.uuid
      data.updateInput.terms = this.userUpdate.terms ? 1 : 0
      data.updateInput.accept_terms = this.userUpdate.terms ? 1 : 0
      const response: LoggedUser | userGetterError = await UserUseCases.updateUser(this.userController, data)

      if ('message' in response) {
        EmitToast.emitErrors(response)
      } else {
        this.$emit('cancel')
        EmitToast.emitSuccess(this.t('userUpdated'))
      }
    },
    async close (): Promise<void> {
      try {
        if (this.isOwner) {
          this.$emit('cancel')
          return
        }
        const hasClosedCompletionInput = this.user?.userData?.find((data) => data.property === 'hasClosedCompletionInput')
        const data: dataToUpdateUser = {
          uuid: this.user?.uuid,
          token: this.token,
          updateInput: {
            appUUID: this.selectedApp?.uuid,
            extradata: []
          }
        }
        if (!hasClosedCompletionInput) {
          data.updateInput.extradata = [{
            property: 'hasClosedCompletionInput',
            value: '1'
          }]
        } else if (+hasClosedCompletionInput.value < 2) {
          data.updateInput.extradata = [{
            property: 'hasClosedCompletionInput',
            value: `${+hasClosedCompletionInput.value + 1}`
          }]
        } else return
        await UserUseCases.updateUser(this.userController, data)
        this.setUserCompletionClose(true)
        this.$emit('cancel')
      } catch (e) {
        console.error(e)
      }
    }
  }
}
