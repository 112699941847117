
import MeetingRepository from '../repository/Meeting.repository'
import type { configActiveApp, dataGetter, dataToGetAdvisorApp, dataToGetMeeting, dataToPutEntitySchedule, dataToPutScheduler, IMeetingController } from '../../domain/MeetingController'

export default class MeetingController implements IMeetingController {
  repository: MeetingRepository

  constructor () {
    this.repository = new MeetingRepository()
  }

  abort (): void {
    this.repository.abortGetSuggestions()
  }

  async getCalendarAuth (data: dataToGetMeeting): Promise<dataGetter> {
    const response = await this.repository.getCalendarAuth(data)
    return { data: response?.data, status: response?.status }
  }

  async getMeetingByAdvisor (data: dataToGetMeeting): Promise<dataGetter> {
    const response = await this.repository.getMeetingByAdvisor(data)
    return { data: response?.data, status: response?.status }
  }

  async getAdvisorByApp (data: dataToGetAdvisorApp): Promise<dataGetter> {
    const response = await this.repository.getAdvisorByApp(data)
    return { data: response?.data, status: response?.status }
  }

  async putEntitySchedule (data: dataToPutEntitySchedule): Promise<dataGetter> {
    const response = await this.repository.putEntitySchedule(data)
    return { data: response?.data, status: response?.status }
  }

  async getScheduler (data: dataToGetAdvisorApp): Promise<configActiveApp> {
    const response = await this.repository.getScheduler(data)
    return { data: response?.data, status: response?.status }
  }

  async putScheduler (data: dataToPutScheduler): Promise<configActiveApp> {
    const response = await this.repository.putScheduler(data)
    return { data: response?.data, status: response?.status }
  }

  async syncScheduler (data: dataToGetAdvisorApp): Promise<configActiveApp> {
    const response = await this.repository.syncScheduler(data)
    return { data: response?.data, status: response?.status }
  }

  async disconnectAuth (data: dataToGetMeeting): Promise<configActiveApp> {
    const response = await this.repository.disconnectAuth(data)
    return { data: response?.data, status: response?.status }
  }

  async disconnectOutlookAuth (data: dataToGetMeeting): Promise<configActiveApp> {
    const response = await this.repository.disconnectOutlookAuth(data)
    return { data: response?.data, status: response?.status }
  }
}
