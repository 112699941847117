import { Adviser } from '../Advisers'
import { RoomChannelsEnum } from '../Room/Room'
import { ActionSuggestion } from '../ActionSuggestions/ActionSuggestion'

export enum MessageErrorCodeEnum {
  CHAT_TAKEN_BY_ANOTHER_ADVISOR = 'CHAT_TAKEN_BY_ANOTHER_ADVISOR',
  ROOM_ENDED = 'ROOM_ENDED',
  WA_CUSTOMER_INACTIVITY = 'WA_CUSTOMER_INACTIVITY',
}

export type MessageErrorCode = MessageErrorCodeEnum;

export enum MessageSendersEnum {
  ADVISOR = 'advisor',
  USER = 'user',
  BOT = 'bot',
  AUTHORIZE = 'authorize',
  LISTENER = 'listener',
  BIKY = 'biky',
}

export type MessageSenders = MessageSendersEnum;

export enum MessageTypesEnum {
  TEXT = 'text',
  AUDIO = 'audio',
  CHAT = 'chat',
  IMAGE = 'image',
  VIDEO = 'video',
  IFRAME = 'iframe',
  SUGGESTION = 'suggestion',
  GIF = 'gif',
  LOCATION = 'location',
  PTT = 'ptt',
  OTHERS = 'others',
  PDF = 'pdf',
  XLS = 'xls',
  XLSX = 'xlsx',
  DOC = 'doc',
  DOCX = 'docx',
  PPT = 'ppt',
  PPTX = 'pptx',
  STATUS = 'status',
  TEMPLATE = 'template',
  MP3 = 'mp3',
  OGG = 'ogg',
  HIDDEN = 'hidden',
  LIST = 'list',
  AUTHORIZE = 'authorize',
  CONTACT = 'contact',
  CONTACTS = 'contacts',
  STICKER = 'sticker',
  CATALOG = 'catalog',
  ORDER = 'order',
  ERROR = 'error',
}

export type MessageType = MessageTypesEnum;

export enum MessageDeliveryStatusEnum {
  PENDING = 'pending', // en transito keybe
  SENDING = 'sending', // transito al proveedor
  FAILED = 'failed', // error al enviar
  SENT = 'sent', // enviado al usuario
  USER = 'user', // recibido por el usuario
  READ = 'read', // leido por el usuario
}

export type DeliveryStatus = MessageDeliveryStatusEnum;

export type BotAction = 'restartContext';

export interface QuotedMessageContent {
  _id: string;
  role: string;
  authorId: string;
  name: string;
  type: string;
  received: number;
  text?: string;
  mediaUrl?: string;
  altText?: string;
  mediaType?: string;
  mediaSize?: number;
}

export interface QuotedMessage {
  type: string;
  content: QuotedMessageContent;
}

export interface MessageReaction {
  reaction: string;
  users: string[];
}

export interface Campaign {
  name: string;
  _id: string;
}

export interface UserCampaign {
  _id?: string;
  userId?: string;
  name?: string;
}

export interface AdminUser {
  id?: string;
  userId?: string;
  name?: string;
}

export enum StatusMessageKeys {
  CONVERSATION_TAKEN_BY_ADVISER = 'CONVERSATION_TAKEN_BY_ADVISER', // Conversación tomada por ${originName}
  CONVERSATION_TRANSFERRED_TO_ADVISER = 'CONVERSATION_TRANSFERRED_TO_ADVISER', // `${originName} ha transferido a ${destinationName}
  CONVERSATION_TRANSFERRED_TO_BOT = 'CONVERSATION_TRANSFERRED_TO_BOT', // ${originName} ha transferido a la IA
  CONVERSATION_TRANSFERRED_TO_SPACE = 'CONVERSATION_TRANSFERRED_TO_SPACE', // ${originName} ha transferido al inbox ${transferRoomData.inbox}
  CONVERSATION_ENDED = 'CONVERSATION_ENDED', // Cerrada por ${adviser.name} como ${payloadMessage}.
  CONVERSATION_ASK_FOR_HELP = 'CONVERSATION_ASK_FOR_HELP', // Esperando ayuda de Agente...
  CONVERSATION_TAKEN_BY_ADVISER_FOR_PROACTIVITY = 'CONVERSATION_TAKEN_BY_ADVISER_FOR_PROACTIVITY', // Conversación tomada por ${adviser.name} al enviar proactividad
  CONVERSATION_ADVISER_ADDED = 'CONVERSATION_ADVISER_ADDED', // ${adviser.name} ${adviser.email} fue agregado
  CONVERSATION_ADVISER_REMOVED = 'CONVERSATION_ADVISER_REMOVED', // ${adviser.name} ${adviser.email} fue eliminado
  AUTOASSIGN_ERROR_INBOX_CONFIG_INCOMPLETE = 'AUTOASSIGN_ERROR_INBOX_CONFIG_INCOMPLETE',
  AUTOASSIGN_ERROR_ROOM_IS_ENDED = 'AUTOASSIGN_ERROR_ROOM_IS_ENDED',
  AUTOASSIGN_ERROR_ROOM_NOT_READY_FOR_ADVISOR_OR_WITH_BOT = 'AUTOASSIGN_ERROR_ROOM_NOT_READY_FOR_ADVISOR_OR_WITH_BOT',
  AUTOASSIGN_ERROR_NO_ADVISERS_AVAILABLE_BY_TAG = 'AUTOASSIGN_ERROR_NO_ADVISERS_AVAILABLE_BY_TAG',
  AUTOASSIGN_ERROR_COULD_NOT_BE_ASSIGNED_USER_WITH_TAG_NOT_FOUND = 'AUTOASSIGN_ERROR_COULD_NOT_BE_ASSIGNED_USER_WITH_TAG_NOT_FOUND',
  AUTOASSIGN_ERROR_ERROR_WHEN_TRANSFERRING_THE_ROOM = 'AUTOASSIGN_ERROR_ERROR_WHEN_TRANSFERRING_THE_ROOM',
  CONVERSATION_ENDED_IA = 'CONVERSATION_ENDED_IA', // Cerrada por finalización automática,
  CONVERSATION_TRANSFERRED_BY_SPACE_DELETED = 'CONVERSATION_TRANSFERRED_BY_SPACE_DELETED', // ${originName} ha transferido al inbox ${transferRoomData.inbox} y eliminado la conversación
}

export interface Payload {
  suggestion?: Array<string>;
  selectedOption?: string;
  transferTo?: string;
  campaing?: Campaign;
  userCampaign?: UserCampaign;
  adminUser?: AdminUser;
  statusKeys: StatusMessageKeys;
  statusData?: {
    executingAdviserName?: string;
    impactedAdviserName?: string;
    impactedAdviserEmail?: string;
    reasonForClosing?: string;
    inboxName?: string;
  }
}

export interface MessageTypeOptions {
  longitude?: string;
  latitude?: string;
}

export interface QuotedMessageKB {
  _id?: string;
  type: MessageType;
  text?: string;
  sender?: MessageSenders;
  payload?: Payload;
  media?: string;
  channelId?: string;
  advisorId?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface Delivery {
  pending?: Date;
  failed?: Date;
  sending?: Date;
  sent?: Date;
  user?: Date;
  read?: Date;
}

export enum MessageOriginEvent {
  CAMPAIGNS = 'campaigns',
  MANUAL = 'manual-proactivity',
  TRIGGER = 'triggers',
}

export interface MessageOrigin {
  event?: string;
  eventId?: string;
  messageId?: string;
  generateIrrelevantResponse?: boolean;
}

export enum WritingType {
  ADVISER = 'adviser',
  MESSAGE_SUGGESTION = 'message-suggestion',
  EDITED_MESSAGE_SUGGESTION = 'edited-message-suggestion',
}

export interface Message {
  _id: string;
  userGuest?: string;
  crmUserId?: string;
  userGuestLocal?: string;
  type: MessageType;
  typeOptions?: MessageTypeOptions;
  status?: string;
  channel?: RoomChannelsEnum;
  companyUUID: string;
  companyName: string;
  appUUID: string;
  appName: string;
  text?: string;
  sender?: MessageSenders;
  payload?: Payload;
  media?: string;
  delivery?: Delivery;
  deliveryStatus?: DeliveryStatus;
  channelId?: string;
  quotedMessage?: QuotedMessage;
  quotedMessageKB?: QuotedMessageKB;
  userHost?: string;
  roomId: string;
  advisorId?: string;
  sessionId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  error?: MessageErrorCode;
  errorSending?: boolean;
  repliedStoryUrl?: string;
  botAction?: BotAction;
  origin?: MessageOrigin;
  adviser?: Adviser;
  actionSuggestions?: ActionSuggestion[];
  writingType?: WritingType;
  reactions?: MessageReaction[];
  mentions?: string[];
}

export enum QuotedProductWhatsappEnum {
  Order = 'order',
  Question = 'question',
}

export interface ProductCatalog {
  id: string;
  name: string;
}

export interface WhatsappProduct {
  id: string;
  retailerId?: string;
  imageUrl?: string;
  name?: string;
  productCatalog: ProductCatalog;
  currency?: string;
  price?: string;
  description?: string;
  inventory?: number;
  salePrice?: string;
  url?: string;
  visibility?: string;
  additionalImageUrls?: string[];
  availability?: string;
  color?: string;
  condition?: string;
  quantity?: number;
}

export interface QuotedProductWhatsapp {
  type:MessageTypesEnum;
  Products: WhatsappProduct[];
}
