import { StatusActive } from '@/Enums/StatusActive'
import { NotificationsSoundKeys } from '@/components/conversations/enums/NotificationsSoundKeys'
import { EventBus } from '@/eventBus'
import { KeybeUserExtradata } from '@/models/KeybeUserExtradata'
import UpdateTeamMember from '@/modules/Config/TeamConfiguration/infrastructure/components/UpdateTeamMember/UpdateTeamMember.vue'
import OnboardingUseCases from '@/modules/SignInOnboarding/application/OnboardingUseCases'
import SignInOnboardingTutorialBox from '@/modules/SignInOnboarding/infrastructure/components/SignInOnboardingTutorialBox/SignInOnboardingTutorialBox.vue'
import OnboardingController from '@/modules/SignInOnboarding/infrastructure/controllers/Onboarding.controller'
import { VecindarioConfigUseCases } from '@/modules/bky/application/VecindarioConfigUseCases'
import { VecindarioConfig, VecindarioRoles } from '@/modules/bky/domain/VecindarioConfig'
import VecindarioConfigController from '@/modules/bky/infrastructure/controllers/VecindarioConfig.controller'
import { ConfigurationTabsEnum } from '@/modules/configuration/application/enums/ConfigurationTabsEnum'
import { loadLanguageAsync } from '@/plugins/i18n/i18n'
import UserService from '@/services/UserService'
import NotificationSound from '@/shared/domain/NotificationSound'
import Colors from '@/shared/domain/colors/Color'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBSmallSelector from '@/shared/infrastructure/ui/inputs/select/KBSmallSelector.vue'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import ChangePassword from '../ChangePassword/ChangePassword.vue'
import GeneralUserConfigurationUserInfo from '../GeneralUserConfigurationUserInfo/GeneralUserConfigurationUserInfo.vue'
import QuickResponses from '../QuickResponses/QuickResponses.vue'
import SessionHistory from '../SessionHistory/SessionHistory.vue'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { dataGetter, dataToGetMeeting } from '../../../domain/MeetingController'
import MeetingUseCases from '../../../application/MeetingUseCases'
import MeetingController from '../../controller/Meeting.controller'
export default {
  name: 'GeneralConfig',
  components: {
    KeybeIcon,
    KBSmallSelector,
    SessionHistory,
    KeybeButton,
    ChangePassword,
    KBSwitch,
    QuickResponses,
    UpdateTeamMember,
    SignInOnboardingTutorialBox,
    GeneralUserConfigurationUserInfo
  },
  mounted () {
    this.getOnboardingStep()
    this.getSchedule()
  },
  data () {
    return {
      CDPFieldsEnums,
      ConfigurationTabsEnum,
      KeybeIconType,
      extradata: null,
      editUserOpen: false,
      Colors,
      changingPassword: false,
      boxPosition: '',
      arrowPosition: 'left',
      rerender: 0,
      headerText: '',
      subtitleText: '',
      buttonText: '',
      showTutorial: true,
      onboardingStep: null,
      modeOptions: [
        {
          label: this.$t('light'),
          value: 'light'
        },
        {
          label: this.$t('dark'),
          value: 'dark'
        }
      ],
      languageOptions: [
        {
          label: this.$t('spanish'),
          value: 'es'
        },
        {
          label: this.$t('english'),
          value: 'en'
        },
        {
          label: this.$t('portuguese'),
          value: 'pt'
        }
      ],
      tones: [
        {
          label: this.$t(NotificationsSoundKeys.KB_CLASSIC),
          value: NotificationsSoundKeys.KB_CLASSIC
        },
        {
          label: this.$t(NotificationsSoundKeys.KB_YEAH),
          value: NotificationsSoundKeys.KB_YEAH
        },
        {
          label: this.$t(NotificationsSoundKeys.KB_CASH),
          value: NotificationsSoundKeys.KB_CASH
        },
        {
          label: this.$t(NotificationsSoundKeys.KB_FEELS),
          value: NotificationsSoundKeys.KB_FEELS
        },
        {
          label: this.$t(NotificationsSoundKeys.KB_HAPPY),
          value: NotificationsSoundKeys.KB_HAPPY
        },
        {
          label: this.$t(NotificationsSoundKeys.KB_SKY),
          value: NotificationsSoundKeys.KB_SKY
        },
        {
          label: this.$t(NotificationsSoundKeys.KB_SYNTH),
          value: NotificationsSoundKeys.KB_SYNTH
        },
        {
          label: this.$t(NotificationsSoundKeys.KB_WHITE),
          value: NotificationsSoundKeys.KB_WHITE
        }
      ],
      loading: false,
      onboardingController: new OnboardingController(),
      vecindarioConfig: null,
      vecindarioConfigController: new VecindarioConfigController(),
      gettingVecindarioUrl: false,
      meetingController: new MeetingController(),
      googleCalendarEnabled: false,
      outlookCalendarEnabled: false,
      googleCalendarEmail: '',
      googleAuth: ''
    }
  },
  computed: {
    ...mapState('UserStore', ['user', 'userExtradata', 'darkMode', 'token']),
    ...mapState('AppStore', ['selectedApp', 'language']),
    ...mapState('ConversationStore', ['sound', 'selectedTone']),
    ...mapGetters('UserStore', ['isCoordinatorOrBelow', 'isAgent']),
    ...mapGetters('AppStore', ['isKeybeApp']),
    computedSound: {
      get () {
        return this.sound
      },
      set (value) {
        this.setSound(value)
      }
    },
    computedSelectedTone: {
      get () {
        return {
          label: this.$t(this.selectedTone),
          value: this.selectedTone
        }
      },
      set (value) {
        if (value) {
          const sound = new NotificationSound(value)
          sound.play()
          this.setSelectedTone(value)
          this.updateUserExtradata({ property: 'tone', value })
        }
      }
    },
    selectedMode: {
      get () {
        return {
          label: this.$t(this.darkMode === StatusActive.ACTIVE ? 'dark' : 'light'),
          value: this.darkMode === StatusActive.ACTIVE ? 'dark' : 'light'
        }
      },
      set (value) {
        // @ts-ignore
        this.setDarkMode(value?.value === 'dark')
      }
    },
    selectedLanguage: {
      get () {
        return {
          label: this.$t(this.language),
          value: this.language
        }
      },
      set (value) {
        // @ts-ignore
        this.changeLanguage(value?.value)
      }
    },
    hasVecindarioConfig (): boolean {
      // Mariana Villada, Nathalia Hernandez
      const exeptionKeybeUsers = ['4gfowi1loopppzp', '8B33P93']
      return this.vecindarioConfig?.active && (!this.isKeybeApp || (this.isKeybeApp && exeptionKeybeUsers.includes(this.user?.userId)))
    }
  },
  methods: {
    ...mapActions('UserStore', ['setUserExtradata']),
    ...mapActions('ConversationStore', ['setSound', 'setSelectedTone']),
    async getSchedule (): Promise<void> {
      const data: dataToGetMeeting = {
        token: this.token,
        appUUID: this.selectedApp?.uuid,
        entityUUID: this.user?.uuid
      }
      const response: dataGetter = await MeetingUseCases.getMeetingByAdvisor(this.meetingController, data)
      if (response?.data?.googleCalendarEnabled) {
        this.googleCalendarEnabled = true
        this.googleCalendarEmail = response?.data?.googleCalendarEmails?.filter((email: { metadata: {
          primary?: boolean,
          sourcePrimary?: boolean,
          verified?: boolean
        }, value: string }) => email?.metadata?.primary)[0]?.value || ''
      } else if (response?.data?.outlookCalendarEnabled) {
        this.outlookCalendarEnabled = true
      } else {
        this.googleCalendarEnabled = false
        this.outlookCalendarEnabled = false
      }
    },
    generateCodeVerifier () {
      const array = new Uint32Array(56)
      crypto.getRandomValues(array)
      return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join('')
    },
    async generateCodeChallenge (codeVerifier: string) {
      const encoder = new TextEncoder()
      const data = encoder.encode(codeVerifier)
      const digest = await crypto.subtle.digest('SHA-256', data)
      return btoa(String.fromCharCode(...new Uint8Array(digest)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '')
    },
    async connectOutlookCalendar () {
      try {
        const codeVerifier = this.generateCodeVerifier()
        const codeChallenge = await this.generateCodeChallenge(codeVerifier)
        const scopes = ['User.Read', 'Calendars.ReadWrite', 'Calendars.Read', 'offline_access', 'openid', 'profile']
        const queryParams: any = {
          client_id: process.env.VUE_APP_OUTLOOK_CLIENT_ID || 'af19c3c9-d217-4420-bbf6-d5209ae67368',
          response_type: 'code',
          redirect_uri: `${process.env.VUE_APP_API_URL}/scheduler/outlook-calendar/redirect`,
          response_mode: 'form_post',
          scope: scopes.join(' '),
          state: `${this.selectedApp.uuid}_${this.user.uuid}_${codeVerifier}`,
          code_challenge: codeChallenge,
          code_challenge_method: 'S256'
        }
        console.log('🚀  queryParams:', queryParams)
        const queryParamsString = Object.keys(queryParams)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
          .join('&')
        window.open(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${queryParamsString}`, '_blank')
        sessionStorage.setItem('code_verifier', codeVerifier)
      } catch (error) {
        console.log('🚀  error:', error)
      }
    },
    async disconectOutlookCalendar (): Promise<void> {
      const data: dataToGetMeeting = {
        token: this.token,
        appUUID: this.selectedApp.uuid || '',
        entityUUID: this.user.uuid
      }
      const response: dataGetter = await MeetingUseCases.disconnectOutlookAuth(this.meetingController, data)
      if (response?.data?.id) {
        this.outlookCalendarEnabled = false
        this.showOutlookCalendarAction = true
        this.showCalendarAction = true
        this.googleCalendarEnabled = false
      }
    },
    async disconectGoogleCalendar (): Promise<void> {
      const data: dataToGetMeeting = {
        token: this.token,
        appUUID: this.selectedApp?.uuid || '' || '',
        entityUUID: this.user?.uuid
      }
      const response: dataGetter = await MeetingUseCases.disconnectAuth(this.meetingController, data)
      if (response?.data?.id) {
        this.googleCalendarEnabled = false
      }
    },
    async connectGoogleCalendar (): Promise<void> {
      const data: dataToGetMeeting = {
        token: this.token,
        appUUID: this.selectedApp?.uuid || '' || '',
        entityUUID: this.user?.uuid
      }
      const response:dataGetter = await MeetingUseCases.getCalendarAuth(this.meetingController, data)
      if (response?.data && response?.data?.url) {
        this.googleAuth = response?.data?.url
        window.open(this.googleAuth, '_blank')
      }
    },
    closeTutorial (): void {
      this.showTutorial = false
      setTimeout(() => {
        this.getOnboardingStep()
      }, 400)
    },
    async getOnboardingStep (): Promise<void> {
      const step: number = await OnboardingUseCases.getOnboardingStep(this.onboardingController)
      this.onboardingStep = step
      this.getItemsPositions()
      this.rerender++
    },
    getItemsPositions (): void {
      switch (this.onboardingStep) {
        case 3:
          this.arrowPosition = 'left'
          this.boxPosition = 'margin-left: -30px; margin-top: 20px'
          this.headerText = 'letsStart'
          this.subtitleText = 'onboardingPercentage'
          this.buttonText = 'nextStep'
          break
        case 4:
          this.arrowPosition = 'left'
          this.boxPosition = 'margin-left: 150px'
          this.headerText = 'smile123'
          this.subtitleText = 'onboardingWeWantToKnowYou'
          this.buttonText = 'uploadProfilePicture'
          break
        case 5:
          this.arrowPosition = 'left'
          this.boxPosition = 'margin-left: 320px; margin-top: -25px'
          this.headerText = 'confirmData'
          this.subtitleText = 'onboardingConfirmData'
          this.buttonText = 'continue'
          break
        case 6:
          this.arrowPosition = 'bottom'
          this.boxPosition = 'margin-left: 340px; margin-top: -40px'
          this.headerText = 'makeItASecret'
          this.subtitleText = 'onboardingPassword'
          this.buttonText = 'stablishPassword'
          break
        case 7:
          this.arrowPosition = 'bottom'
          this.boxPosition = 'margin-left: 340px; margin-top: 45px'
          this.headerText = 'youReplies'
          this.subtitleText = 'onboardingReplies'
          this.buttonText = 'configureReplies'
          break
      }
    },
    onboardingAction (): void {
      switch (this.onboardingStep) {
        case 3:
          this.nextStep()
          break
        case 4:
          EventBus.$emit('openUserImageUpload--general-config')
          break
        case 5:
          this.nextStep()
          break
        case 6:
          this.changingPassword = true
          this.showTutorial = false
          break
        case 7:
          EventBus.$emit('openQuickResponses--general-config')
          this.showTutorial = false
          break
      }
    },
    checkStep () {
      if (this.onboardingStep === 4) this.nextStep()
    },
    closeChangePassword (): void {
      this.changingPassword = false
      this.showTutorial = true
      if (this.onboardingStep === 6) this.nextStep()
    },
    async nextStep (): Promise<void> {
      this.showTutorial = false
      // eslint-disable-next-line no-undef
      const newStep: number = structuredClone(this.onboardingStep) + 1
      await OnboardingUseCases.setOnboardingStep(this.onboardingController, newStep)
      this.onboardingStep = newStep
      this.getItemsPositions()
      setTimeout(() => {
        if (this.onboardingStep < 8) this.showTutorial = true
        if (this.onboardingStep === 8) {
          EventBus.$emit('setConfigurationTab', ConfigurationTabsEnum.APP_INFO)
        }
      }, 400)
      this.rerender++
    },
    setDarkMode (mode) {
      this.$vuetify.theme.dark = mode
      this.$store.commit('UserStore/SET_DARK_MODE', this.$vuetify.theme.dark ? StatusActive.ACTIVE : StatusActive.INACTIVE)
    },
    async changeLanguage (lang) {
      if (this.loading) {
        return
      }
      await loadLanguageAsync(lang)
      this.localLang = lang
      this.$store.commit('AppStore/SET_APP_LANGUAGE', lang)

      const data = {
        property: 'lang',
        value: lang
      }
      const response = await UserService.updateUserExtradata(data)
      if (response.status) {
        this.extradata.updateExtradata(data)
        const newExtradata = this.extradata.getExtradata()
        await this.setUserExtradata(newExtradata)
      }
    },
    async updateUserExtradata (extradata) {
      const data = {
        property: extradata.property,
        value: extradata.value
      }
      const response = await UserService.updateUserExtradata(data)
      if (response.status) {
        this.extradata.updateExtradata(data)
        const newExtradata = this.extradata.getExtradata()
        this.setUserExtradata(newExtradata)
      }
    },
    async getConfig (): Promise<void> {
      const config: VecindarioConfig = await VecindarioConfigUseCases.getConfig(this.vecindarioConfigController, {
        appUUID: this.selectedApp?.uuid,
        token: this.token
      })

      this.vecindarioConfig = config
    },
    async openUserConfigUrl (): Promise<void> {
      if (this.gettingVecindarioUrl) return
      this.gettingVecindarioUrl = true
      this.$emit('start-loading')
      const url: string = await VecindarioConfigUseCases.getUserConfigUrl(this.vecindarioConfigController, {
        token: this.token,
        data: {
          role: this.isAgent ? VecindarioRoles.ASESOR : VecindarioRoles.LIDER,
          email: this.user?.email,
          modules: ['agendador'],
          name: this.user?.name,
          lastName: this.user?.lastName,
          phone: this.user?.phone,
          slug: this.vecindarioConfig?.vecindarioSlug,
          appUUID: this.selectedApp?.uuid,
          uuid: this.user?.uuid
        }
      })
      this.$emit('stop-loading')
      this.gettingVecindarioUrl = false

      if (!url) {
        EventBus.$emit('toast', 'error', this.$t('errorGettingConfigUrl'))
        return
      }

      window.open(url, '_blank')
    }
  },
  async created () {
    this.loading = true
    this.extradata = new KeybeUserExtradata(this.userExtradata)
    if (this.language) {
      this.selectedLanguage = this.languageOptions.find((item) => item.value === this.language)
    }
    const tone = this.extradata.getTone()
    if (tone) {
      this.setSelectedTone(tone)
    }
    this.getConfig()
    this.loading = false
  },
  beforeDestroy () {
    VecindarioConfigUseCases.abortAll(this.vecindarioConfigController)
  }
}
