import type { configActiveApp, dataGetter, dataToGetAdvisorApp, dataToGetMeeting, dataToPutEntitySchedule, dataToPutScheduler, IMeetingController } from '../domain/MeetingController'

export default class MeetingUseCases {
  static abort (controller: IMeetingController) : void {
    controller.abort()
  }

  static getScheduler (controller: IMeetingController, data: dataToGetAdvisorApp) : Promise<configActiveApp> {
    return controller.getScheduler(data)
  }

  static putScheduler (controller: IMeetingController, data: dataToPutScheduler) : Promise<configActiveApp> {
    return controller.putScheduler(data)
  }

  static syncScheduler (controller: IMeetingController, data: dataToGetAdvisorApp) : Promise<configActiveApp> {
    return controller.syncScheduler(data)
  }

  static getCalendarAuth (controller: IMeetingController, data: dataToGetMeeting) : Promise<dataGetter> {
    return controller.getCalendarAuth(data)
  }

  static getMeetingByAdvisor (controller: IMeetingController, data: dataToGetMeeting) : Promise<dataGetter> {
    return controller.getMeetingByAdvisor(data)
  }

  static getAdvisorByApp (controller: IMeetingController, data: dataToGetAdvisorApp) : Promise<dataGetter> {
    return controller.getAdvisorByApp(data)
  }

  static putEntitySchedule (controller: IMeetingController, data: dataToPutEntitySchedule) : Promise<dataGetter> {
    return controller.putEntitySchedule(data)
  }

  static disconnectAuth (controller: IMeetingController, data: dataToGetMeeting) : Promise<configActiveApp> {
    return controller.disconnectAuth(data)
  }

  static disconnectOutlookAuth (controller: IMeetingController, data: dataToGetMeeting): Promise<configActiveApp> {
    return controller.disconnectOutlookAuth(data)
  }
}
